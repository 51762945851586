import { Component, Inject, OnInit } from "@angular/core";
import {
  MissionPartnerAccount,
  TalentAccount,
} from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.types";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { AccountService } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-account-password-dialog",
  templateUrl: "./account-password-dialog.component.html",
  styleUrls: ["./account-password-dialog.component.less"],
})
export class AccountPasswordDialogComponent implements OnInit {
  account: TalentAccount | MissionPartnerAccount;
  validateForm!: UntypedFormGroup;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private notificationService: NzNotificationService,
    private readonly translateService: TranslateService,
  ) {
    this.account = this.modalData["account"];
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      accountId: [this.account.accountId.internalId],
      password: [null, [Validators.required]],
    });
  }

  onCancel(): void {
    this.modal.close();
  }

  onSave(): void {
    if (this.validateForm.valid) {
      const formValue = this.validateForm.value;
      this.accountService.updatePassword(formValue.accountId, formValue.password).subscribe((success) => {
        if (success) {
          this.notificationService.success("", this.translateService.instant("admin.set-password.success"));
          this.modal.close();
        } else {
          this.notificationService.error("", this.translateService.instant("notification.error"));
        }
      });
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
