import { Component, Input } from "@angular/core";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { ImageCroppedEvent, LoadedImage } from "ngx-image-cropper";
import { DomSanitizer } from "@angular/platform-browser";
import { ProfileService } from "../../api/profile/profile.service";
import { catchError, throwError } from "rxjs";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
  selector: "mh-avatar-upload",
  templateUrl: "./avatar-upload.component.html",
  styleUrls: ["./avatar-upload.component.less"],
})
export class AvatarUploadComponent {
  @Input() avatarUrl!: string;
  @Input() isLogo = false;
  loading = false;
  uploading = false;
  fileList: NzUploadFile[] = [];

  constructor(
    private profileService: ProfileService,
    private sanitizer: DomSanitizer,
    private notificationService: NzNotificationService,
  ) {}

  beforeUpload = (file: NzUploadFile): boolean => {
    const isPictureType = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp";
    if (!isPictureType) {
      this.notificationService.error("", "File type not supported. Supported types: jpeg, png, webp");
      return false;
    }
    const isLt10M = file.size! / 1024 / 1024 < 10;
    if (!isLt10M) {
      this.notificationService.error("", "File too large. Supported size < 10Mb.");
      return false;
    }
    if (isPictureType && isLt10M) {
      if (this.isLogo) {
        this.handleLogoUpload(file);
      } else {
        this.loading = true;
        this.imageFile = file;
        this.showModal();
      }
    }
    return false;
  };

  handleLogoUpload(file: NzUploadFile): void {
    const formData = new FormData();
    formData.append("file", file as any);
    this.uploading = true;
    this.profileService
      .uploadUserAvatar(formData)
      .pipe(
        catchError((error) => {
          this.uploading = false;
          return throwError(() => new Error(error));
        }),
      )
      .subscribe((result) => {
        this.uploading = false;
        // Update the preview immediately after upload
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(e.target.result);
        };
        reader.readAsDataURL(file as any);
      });
  }

  handleUpload(): void {
    const formData = new FormData();
    formData.append("file", this.croppedImageBlob as Blob);
    this.uploading = true;
    this.profileService
      .uploadUserAvatar(formData)
      .pipe(
        catchError((error) => {
          this.uploading = false;
          return throwError(() => new Error(error));
        }),
      )
      .subscribe((result) => {
        this.uploading = false;
      });
  }

  imageFile: any = "";
  croppedImage: any = "";
  croppedImageBlob!: Blob | null | undefined;

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl as any);
    this.croppedImageBlob = event.blob;
    //const bytes = event.blob?.size || 0;
    //console.log("Cropped image size: " + (bytes / 1048576).toFixed(2) + " MB");

    // event.blob can be used to upload the cropped image
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
    this.loading = false;
  }
  loadImageFailed() {
    // show message
  }

  //modal
  isVisible = false;

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
    this.handleUpload();
  }

  handleCancel(): void {
    this.isVisible = false;
    this.croppedImage = undefined;
    this.imageFile = undefined;
  }
}
