<ng-container *ngIf="(isConversationLoading$ | async) === false; else loadingTmpl">
  <ng-container *ngIf="conversation$ | async as conversation">
    <div nz-row class="layout-conversation" [nzGutter]="12">
      <div nz-col [nzSpan]="24" [nzMd]="8">
        <!-- left col:  -->

        <div class="conversation-left-col">
          <button nz-button nzType="link" (click)="onBack()">
            <span nz-icon nzType="left"></span>
            {{ "inbox.back.button" | translate }}
          </button>
          <div class="conversation-participant">
            <div
              class="contact"
              [routerLink]="[
                loginData.accountType === AccountType.TALENT
                  ? '/talent/view-micro-site/'
                  : '/mission-partner/view-talent/',
                conversation.otherParticipantId
              ]"
            >
              <nz-avatar
                class="mh-avatar"
                [class.mh-avatar--logo]="loginData.accountType === AccountType.TALENT"
                [nzSrc]="conversation.recipientInfo?.avatarUrl || ''"
                [nzSize]="60"
              ></nz-avatar>
              <div class="contact-name">
                <ng-container *ngIf="conversation.recipientInfo?.companyName"
                  ><b>{{ conversation.recipientInfo?.companyName }}</b
                  ><br
                /></ng-container>
                <ng-container *ngIf="!conversation.recipientInfo?.companyName">
                  {{ conversation.recipientInfo?.name }}
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="loginData.accountType === AccountType.MISSION_PARTNER">
              <button
                nz-button
                nzType="default"
                nzBlock
                [routerLink]="['/mission-partner', 'view-talent', getTalentId()]"
              >
                {{ "inbox.view-profile.button" | translate }}
              </button>
              <button
                *ngIf="conversation.accessGrant?.status !== AccessGrantStatus.GRANTED"
                nz-button
                nzType="default"
                nzBlock
                (click)="onRequestProfileAccess()"
                [disabled]="
                  isMPRequestDetailsDisabled || conversation.accessGrant?.status === AccessGrantStatus.GRANTED
                "
              >
                {{ "inbox.asc-details.button" | translate }}
              </button>
            </ng-container>

            <ng-container *ngIf="loginData.accountType === AccountType.TALENT">
              <button
                nz-button
                nzType="default"
                nzBlock
                (click)="onGrantProfileAccess()"
                [disabled]="isConversationDisabled || conversation.accessGrant?.status === AccessGrantStatus.GRANTED"
              >
                {{ "inbox.open-details.button" | translate }}
                <span
                  *ngIf="conversation.accessGrant?.status === AccessGrantStatus.GRANTED"
                  nz-icon
                  nzType="check"
                ></span>
              </button>
              <button
                *ngIf="conversation.accessGrant?.status === AccessGrantStatus.GRANTED"
                nz-button
                nzType="default"
                nzBlock
                (click)="onRejectProfileAccess()"
                [disabled]="isConversationDisabled"
              >
                {{ "inbox.restrict-details.button" | translate }}
              </button>
            </ng-container>

            <button
              *ngIf="!isAgreementReached"
              nz-button
              nzType="default"
              nzBlock
              (click)="isOneSideAgreement ? openRespondAgreementTermsModal() : openProposeAgreementTermsModal()"
              [disabled]="isConversationDisabled || isMySideAgreement"
            >
              <ng-container *ngIf="!isOneSideAgreement">
                {{ "inbox.asc-agreement-reached.button" | translate }}
              </ng-container>
              <ng-container *ngIf="isOneSideAgreement">
                {{
                  (isMySideAgreement
                    ? "inbox.you-confirmed-agreement-reached.button"
                    : "inbox.confirm-agreement-reached.button"
                  ) | translate
                }}
              </ng-container>
            </button>

            <nz-alert
              class="alert-cnt-center agreement-alert"
              *ngIf="isAgreementReached"
              nzType="success"
              [nzMessage]="'conversation.agreement.reached.text' | translate"
              nzShowIcon
            ></nz-alert>

            <button
              nz-button
              nzType="default"
              nzBlock
              [disabled]="isConversationDisabled"
              (click)="onConversationClose()"
            >
              {{ "inbox.conversation-close.button" | translate }}
            </button>

            <mh-chat-participant-info
              [participantProfile]="conversation.recipientInfo"
              [accountType]="loginData.accountType"
            ></mh-chat-participant-info>

            <!-- Add Agreement Terms display -->
            <ng-container
              *ngIf="
                conversation.negotiation?.agreementTerms &&
                conversation.negotiation?.status !== NegotiationStatus.INITIATED &&
                conversation.negotiation?.status !== NegotiationStatus.ACTIVE &&
                conversation.negotiation?.status !== NegotiationStatus.CLOSED
              "
            >
              <nz-divider></nz-divider>
              <mh-agreement-terms-info
                [agreementTerms]="conversation.negotiation?.agreementTerms"
                [accountType]="loginData.accountType"
              ></mh-agreement-terms-info>
            </ng-container>
          </div>
        </div>

        <!-- Mobile header -->
        <Navbar
          [leftContent]="navLeftContent"
          [rightContent]="navRightContent"
          [mode]="'light'"
          class="chat-mobile-header fixed"
        >
          <nz-avatar
            class="mh-avatar"
            [class.mh-avatar--logo]="loginData.accountType === AccountType.TALENT"
            [nzSrc]="conversation.recipientInfo?.avatarUrl || ''"
            [nzSize]="47"
          ></nz-avatar>
          <div class="contact-name">
            <ng-container *ngIf="conversation.recipientInfo?.companyName"
              ><b>{{ conversation.recipientInfo?.companyName }}</b
              ><br
            /></ng-container>

            {{ conversation.recipientInfo?.name }}
          </div>
        </Navbar>
        <ng-template #navLeftContent>
          <Icon [type]="'left'" [size]="'lg'" (click)="onBack()" class="back-button"></Icon>
        </ng-template>
        <ng-template #navRightContent>
          <Icon
            *ngIf="!isConversationDisabled"
            Popover
            [ngStyle]="{ color: '#000', display: 'flex', 'align-items': 'center' }"
            [mask]="true"
            [showArrow]="true"
            [overlay]="overlay"
            [type]="'ellipsis'"
            [placement]="'bottomRight'"
          ></Icon>
        </ng-template>

        <ng-template #overlay
          ><!-- mobile header popover menu items -->
          <ng-container *ngIf="loginData.accountType === AccountType.MISSION_PARTNER">
            <PopoverItem class="nowrap" [routerLink]="['/mission-partner', 'view-talent', getTalentId()]">
              {{ "inbox.view-profile.button" | translate }}
            </PopoverItem>
            <PopoverItem
              class="nowrap"
              *ngIf="conversation.accessGrant?.status !== AccessGrantStatus.GRANTED"
              (click)="onRequestProfileAccess()"
              [disabled]="isMPRequestDetailsDisabled || conversation.accessGrant?.status === AccessGrantStatus.GRANTED"
            >
              {{ "inbox.asc-details.button" | translate }}
            </PopoverItem>
          </ng-container>
          <ng-container *ngIf="loginData.accountType === AccountType.TALENT">
            <PopoverItem
              class="nowrap"
              (click)="onGrantProfileAccess()"
              [disabled]="isConversationDisabled || conversation.accessGrant?.status === AccessGrantStatus.GRANTED"
            >
              {{ "inbox.open-details.button" | translate }}
              <span
                *ngIf="conversation.accessGrant?.status === AccessGrantStatus.GRANTED"
                nz-icon
                nzType="check"
              ></span>
            </PopoverItem>
            <PopoverItem
              class="nowrap"
              *ngIf="conversation.accessGrant?.status === AccessGrantStatus.GRANTED"
              (click)="onRejectProfileAccess()"
              [disabled]="isConversationDisabled"
            >
              {{ "inbox.restrict-details.button" | translate }}
            </PopoverItem>
          </ng-container>

          <PopoverItem
            *ngIf="!isAgreementReached"
            class="nowrap"
            (click)="openProposeAgreementTermsModal()"
            [disabled]="isConversationDisabled || isMySideAgreement"
          >
            <ng-container *ngIf="!isOneSideAgreement">
              {{ "inbox.asc-agreement-reached.button" | translate }}
            </ng-container>
            <ng-container *ngIf="isOneSideAgreement">
              {{
                (isMySideAgreement
                  ? "inbox.you-confirmed-agreement-reached.button"
                  : "inbox.confirm-agreement-reached.button"
                ) | translate
              }}
            </ng-container>
          </PopoverItem>
          <PopoverItem class="nowrap" *ngIf="isAgreementReached" [disabled]="true">
            {{ "conversation.agreement.reached.text" | translate }}
            <span nz-icon nzType="check-circle" nzTheme="twotone" [twoToneColor]="'#52c41a'"></span>
          </PopoverItem>
          <PopoverItem class="nowrap" [disabled]="isConversationDisabled" (click)="onConversationClose()">
            {{ "inbox.conversation-close.button" | translate }}
          </PopoverItem> </ng-template
        ><!-- / mobile header popover menu items -->
      </div>
      <div nz-col [nzSpan]="24" [nzMd]="16" class="layout-conversation-chat">
        <!-- right col: Chat -->
        <mh-messages-conversation-chat
          [loginData]="loginData"
          [messages]="conversation.messages"
          [ownerInfo]="conversation.ownerInfo"
          [recipientInfo]="conversation.recipientInfo"
          [accessGrant]="conversation.accessGrant"
          [negotiation]="conversation.negotiation"
          [uploadingFile]="uploadingFile"
          [disabled]="isConversationDisabled"
          (sendMessage)="onSendMessage($event)"
          (loadOlderMessages)="onLoadOlderMessages($event)"
          (messageAction)="onMessageAction($event)"
        >
        </mh-messages-conversation-chat>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingTmpl>
  <div class="loading">
    <nz-spin nzSimple></nz-spin>
  </div>
</ng-template>
