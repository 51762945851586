import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewContainerRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { messagesFeature, MessagesState } from "../store/messages.reducer";
import {
  AccessGrantStatus,
  AgreementTerms,
  Conversation,
  ConversationStatus,
  FileSubType,
  MessageSubType,
  MessagingMessage,
  SocketAction,
} from "../../../api/messages/messages.types";
import { MessagesAction } from "../store/messages.action";
import { Observable, Subscription, tap } from "rxjs";
import { SocketService } from "../../../../../../core/src/lib/soket/socket.service";
import { MhNotificationType, MhSocketMessage } from "../../../api/notification/notification.types";
import { isConversationLoading, selectConversation } from "../store/messages.selectors";
import { MessagesSerializer } from "../../../api/messages/messages-serializer.service";
import { AuthorizationService } from "../../../api/authorization/authorization.service";
import { ChatUserInput, UiMessageAction } from "./messages-conversation-chat/messages-chat.types";
import { DocumentService } from "../../../api/document/document.service";
import { isImageFile } from "../is-image-file";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { AccountStatus, AccountType, LoginData } from "../../../pages/login-page/login-page-data/login.types";
import { NegotiationStatus } from "../../../pages/home-admin/home-admin-data/account.types";
import { Router } from "@angular/router";
import { AgreementTermsDialogComponent } from "./agreement-terms-conversation-dialog/agreement-terms-dialog.component";

@Component({
  selector: "mh-messages-conversation",
  templateUrl: "./messages-conversation.component.html",
  styleUrls: ["./messages-conversation.component.less"],
})
export class MessagesConversationComponent implements OnInit, OnDestroy {
  @Output() back = new EventEmitter();
  conversation$: Observable<Conversation>;
  conversation!: Conversation;
  isConversationLoading$: Observable<boolean>;
  loginData!: LoginData;
  uploadingFile: File | null = null;

  private subscriptions: Subscription[] = [];
  private hasPreviousNavigation;

  get isConversationDisabled(): boolean {
    return (
      this.conversation.status === ConversationStatus.ENDED ||
      this.conversation.status === ConversationStatus.DELETED ||
      this.conversation.status === ConversationStatus.OTHER_SIDE_DELETED ||
      (this.loginData.accountType === AccountType.MISSION_PARTNER &&
        !this.loginData.accountStatuses.includes(AccountStatus.REVIEWED)) ||
      (this.loginData.accountType === AccountType.MISSION_PARTNER &&
        !this.loginData.accountStatuses.includes(AccountStatus.SELF_SERVICE) &&
        !this.loginData.adminOverrideMode)
    );
  }

  get isMPRequestDetailsDisabled(): boolean {
    return this.isConversationDisabled || this.conversation.accessGrant?.status == AccessGrantStatus.PENDING;
  }

  get isAgreementReached(): boolean {
    return this.conversation.negotiation?.status == NegotiationStatus.COMPLETED;
  }

  get isMySideAgreement(): boolean {
    switch (this.loginData.accountType) {
      case AccountType.TALENT:
        return this.conversation.negotiation?.status === NegotiationStatus.TALENT_AGREEMENT_REACHED;
      case AccountType.MISSION_PARTNER:
        return this.conversation.negotiation?.status === NegotiationStatus.MP_AGREEMENT_REACHED;
      default:
        return false;
    }
  }

  get isOneSideAgreement(): boolean {
    return (
      this.conversation.negotiation?.status === NegotiationStatus.TALENT_AGREEMENT_REACHED ||
      this.conversation.negotiation?.status === NegotiationStatus.MP_AGREEMENT_REACHED
    );
  }

  constructor(
    private readonly store: Store<{ [messagesFeature]: MessagesState }>,
    private messagesSerializer: MessagesSerializer,
    private socketService: SocketService,
    private authorizationService: AuthorizationService,
    private documentService: DocumentService,
    private translateService: TranslateService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
  ) {
    this.hasPreviousNavigation = Boolean(this.router.getCurrentNavigation()?.previousNavigation);
    this.conversation$ = this.store
      .select(selectConversation)
      .pipe(tap((conversation) => (this.conversation = conversation)));
    this.isConversationLoading$ = this.store.select(isConversationLoading);

    this.subscriptions.push(
      this.authorizationService.getAuthorizedUser().subscribe((loginData) => {
        this.loginData = loginData;
      }),
    );

    this.subscriptions.push(
      this.socketService.messages$.subscribe((message: MhSocketMessage) => {
        if (
          message.action === MhNotificationType.COMMUNICATION_MESSAGE &&
          this.conversation?.id !== message.message?.conversationId
        ) {
          return;
        }
        if (
          message.action === MhNotificationType.CONVERSATION_STATUS_UPDATED &&
          this.conversation?.id !== message.conversationId
        ) {
          return;
        }
        if (message.action === MhNotificationType.COMMUNICATION_MESSAGE && message.message) {
          this.store.dispatch(
            MessagesAction.receiveMessageSuccess({
              message: this.messagesSerializer.deserializeMessagingMessage(message.message, this.loginData.accountId),
            }),
          );
          this.store.dispatch(
            MessagesAction.readMessages({
              ids: [message.message.id as string],
            }),
          );
        }
        if (message.action === MhNotificationType.CONVERSATION_STATUS_UPDATED && message.conversationId) {
          if (message.status === ConversationStatus.ACTIVE) {
            this.store.dispatch(MessagesAction.updateNegotiationStatus({ status: NegotiationStatus.ACTIVE }));
          }
          if (message.status === ConversationStatus.COMPLETED) {
            this.store.dispatch(MessagesAction.updateNegotiationStatus({ status: NegotiationStatus.COMPLETED }));
          }
          if (message.status === ConversationStatus.ENDED) {
            this.store.dispatch(MessagesAction.closeConversationSuccess({ id: message.conversationId, success: true }));
          }
          if (message.status === ConversationStatus.OTHER_SIDE_DELETED) {
            this.store.dispatch(
              MessagesAction.deleteConversationSuccess({ id: message.conversationId, success: true }),
            );
          }
        }
        if (message.action === MhNotificationType.COMMUNICATION_MESSAGE) {
          if (
            this.loginData.accountType === AccountType.MISSION_PARTNER &&
            (message.message?.subType === MessageSubType.PROFILE_ACCESS_GRANTED ||
              message.message?.subType === MessageSubType.PROFILE_ACCESS_REJECTED)
          ) {
            if (this.conversation) {
              this.store.dispatch(MessagesAction.loadConversation({ id: this.conversation.id }));
            }
          }
          if (message.message?.subType === MessageSubType.PROFILE_ACCESS_QUESTION) {
            this.store.dispatch(
              MessagesAction.requestProfileAccessSuccess({
                data: { status: AccessGrantStatus.PENDING },
                conversationId: message.message.conversationId as string,
              }),
            );
          }
        }
        if (
          message.action === MhNotificationType.COMMUNICATION_MESSAGE &&
          message.message?.subType === MessageSubType.AGREEMENT_REACHED_ONE_SIDE
        ) {
          const messagingMessage = this.messagesSerializer.deserializeMessagingMessage(
            message.message,
            this.loginData.accountId,
          );
          let newStatus: NegotiationStatus;
          if (this.loginData.accountType === AccountType.TALENT) {
            newStatus = messagingMessage.ownMessage
              ? NegotiationStatus.TALENT_AGREEMENT_REACHED
              : NegotiationStatus.MP_AGREEMENT_REACHED;
          } else {
            newStatus = messagingMessage.ownMessage
              ? NegotiationStatus.MP_AGREEMENT_REACHED
              : NegotiationStatus.TALENT_AGREEMENT_REACHED;
          }
          this.store.dispatch(MessagesAction.updateNegotiationStatus({ status: newStatus }));
          this.store.dispatch(MessagesAction.updateAgreementTerms({ id: this.conversation.id }));
        }
        if (
          message.action === MhNotificationType.COMMUNICATION_MESSAGE &&
          message.message?.subType === MessageSubType.AGREEMENT_REACHED_COMPLETED
        ) {
          this.store.dispatch(MessagesAction.updateNegotiationStatus({ status: NegotiationStatus.COMPLETED }));
        }
        if (
          message.action === MhNotificationType.VIDEO_TRANSCODED &&
          message.file?.conversationId == this.conversation.id
        ) {
          this.store.dispatch(
            MessagesAction.updateMessageVideo({
              message: {
                attachment: { beingTranscoded: false, name: message.file?.name },
              } as Partial<MessagingMessage>,
            }),
          );
        }
      }),
    );
  }

  ngOnInit() {
    if (!this.hasPreviousNavigation) {
      // Update header msg counter after conversation loaded as first page
      this.store.dispatch(MessagesAction.loadConversationList({ filter: { paging: { page: 0, itemsOnPage: 10 } } }));
      this.subscriptions.push(
        this.store
          .select((state) => state[messagesFeature].conversationList.status.loaded)
          .subscribe((loaded) => {
            if (loaded) this.store.dispatch(MessagesAction.getUnseenMessageCount());
          }),
      );
    }
  }

  onBack() {
    this.back.emit();
  }

  getMissionPartnerId(): string {
    return this.conversation.isOwner ? this.conversation.otherParticipantId : this.conversation.ownerId;
  }
  getTalentId(): string {
    return this.conversation.isOwner ? this.conversation.otherParticipantId : this.conversation.ownerId;
  }

  onSendMessage(userInput: ChatUserInput) {
    let message: MessagingMessage;
    if (userInput.fileList?.length) {
      const formData = new FormData();
      const file = userInput.fileList[0];
      formData.append("file", file, file.name);
      formData.append("conversationId", this.conversation.id);
      if (isImageFile(file)) {
        formData.append("fileType", FileSubType.IMAGE);
      } else if (file.type.startsWith("video/")) {
        formData.append("fileType", FileSubType.VIDEO);
      } else if (file.type.startsWith("audio/")) {
        formData.append("fileType", FileSubType.AUDIO);
      }
      this.uploadingFile = file;
      this.documentService.uploadMessagingFile(formData).subscribe((uploaded) => {
        this.uploadingFile = null;
        message = {
          action: SocketAction.SEND,
          conversationId: this.conversation.id,
          messageText: userInput.content,
          attachmentId: uploaded.id,
          creationTimestamp: new Date(),
          ownMessage: true,
        };
        this.store.dispatch(MessagesAction.sendMessage({ message }));
      });
    } else {
      message = {
        action: SocketAction.SEND,
        conversationId: this.conversation.id,
        messageText: userInput.content,
        attachmentId: undefined,
        creationTimestamp: new Date(),
        ownMessage: true,
      };
      this.store.dispatch(MessagesAction.sendMessage({ message }));
    }
  }

  onLoadOlderMessages(lastMsgId: string) {
    this.store.dispatch(MessagesAction.loadOlderMessages({ conversationId: this.conversation.id, lastMsgId }));
  }

  onConversationClose() {
    this.modal.confirm({
      nzTitle: this.translateService.instant("inbox.confirm.close.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.store.dispatch(MessagesAction.closeConversation({ id: this.conversation.id })),
    });
  }

  onRequestProfileAccess() {
    //MP request profile access
    const talentId = this.getTalentId();
    this.store.dispatch(MessagesAction.requestProfileAccess({ talentId, conversationId: this.conversation.id }));
  }

  onGrantProfileAccess() {
    //talent can grant profile access
    const missionPartnerId = this.getMissionPartnerId();
    this.store.dispatch(MessagesAction.grantProfileAccess({ missionPartnerId }));
  }

  onRejectProfileAccess() {
    //talent reject profile access
    const missionPartnerId = this.getMissionPartnerId();
    this.store.dispatch(MessagesAction.rejectProfileAccess({ missionPartnerId }));
  }

  onMessageAction(action: UiMessageAction) {
    switch (action) {
      case "grant_profile_access":
        this.onGrantProfileAccess();
        break;
      case "reject_profile_access":
        this.onRejectProfileAccess();
        break;
      case "grant_agreement_reached":
        this.isOneSideAgreement ? this.openRespondAgreementTermsModal() : this.openProposeAgreementTermsModal();
        break;
    }
  }

  openProposeAgreementTermsModal() {
    this.modal.create({
      nzContent: AgreementTermsDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: { conversation: this.conversation },
      nzWidth: 800,
      nzOnOk: (modalCmp) => this.proposeAgreementTerms(this.conversation.id, modalCmp.formGroup.value),
    });
  }

  openRespondAgreementTermsModal() {
    const modal = this.modal.create({
      nzContent: AgreementTermsDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: { conversation: this.conversation, isRespond: true },
      nzWidth: 800,
      nzOnOk: () => this.respondAgreementTerms(this.conversation.id, true),
    });

    modal.afterClose.subscribe((result) => {
      if (result === "reject") {
        this.respondAgreementTerms(this.conversation.id, false);
      }
    });
  }

  proposeAgreementTerms(conversationId: string, formData: AgreementTerms) {
    this.store.dispatch(MessagesAction.proposeAgreementTerms({ conversationId, terms: formData }));
  }

  respondAgreementTerms(conversationId: string, accepted: boolean) {
    this.store.dispatch(MessagesAction.respondToAgreementTerms({ conversationId, accepted }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  protected readonly ConversationStatus = ConversationStatus;
  protected readonly AccountType = AccountType;
  protected readonly AccessGrantStatus = AccessGrantStatus;
  protected readonly NegotiationStatus = NegotiationStatus;
}
