import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminNegotiationListComponent } from "./admin-negotiation-list.component";
import { TranslateModule } from "@ngx-translate/core";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { ReactiveFormsModule } from "@angular/forms";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink } from "@angular/router";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NegotiationDetailsDialogModule } from "../../../../../../../common-ui/src/lib/negotiation-details-dialog/negotiation-details-dialog.module";

@NgModule({
  declarations: [AdminNegotiationListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NzTableModule,
    NzDividerModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzToolTipModule,
    NzButtonModule,
    RouterLink,
    NegotiationDetailsDialogModule,
  ],
})
export class AdminNegotiationListModule {}
