import { Component, Input } from "@angular/core";
import { AccountType } from "../../../../../pages/login-page/login-page-data/login.types";
import { AgreementTerms } from "../../../../../api/messages/messages.types";

@Component({
  selector: "mh-agreement-terms-info",
  templateUrl: "./mh-agreement-terms-info.component.html",
  styleUrls: ["./mh-agreement-terms-info.component.less"],
})
export class MhAgreementTermsInfoComponent {
  @Input() agreementTerms: AgreementTerms | undefined;
  @Input() accountType: AccountType | undefined;
  protected readonly AccountType = AccountType;
}
