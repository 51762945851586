import { Component, ElementRef, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import {
  JobOffer,
  JobOpportunitySearchResultStatus,
  StageUpdateEvent,
} from "../../../../../../api/job-offer/job-offer.types";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Observable, map } from "rxjs";
import { CmsNameValue } from "libs/platform-pages/src/lib/api/cms/cms.types";
import { CmsCachedService } from "libs/platform-pages/src/lib/api/cms/cms-cached.service";

@Component({
  selector: "mh-mp-job-offer-item",
  templateUrl: "./mp-job-offer-item.component.html",
  styleUrls: ["./mp-job-offer-item.component.less"],
  animations: [
    trigger("showMore", [
      state(
        "show",
        style({
          height: "*",
        }),
      ),
      state(
        "hide",
        style({
          height: "63px",
        }),
      ),
      transition("show <=> hide", [animate(".7s")]),
    ]),
  ],
})
export class MpJobOfferItemComponent {
  @Input() jobOffer!: JobOffer;
  @Output() stageUpdated = new EventEmitter<StageUpdateEvent>();

  isExpanded = false;
  canExpand = false;

  @ViewChild("jobOfferDescription") jobOfferDescription?: ElementRef;

  jobOpportunityStages$: Observable<Array<CmsNameValue>>;

  get stageName() {
    return this.jobOpportunityStages$.pipe(map((stages) => stages.find((s) => s.value === this.jobOffer.stage)?.name));
  }

  constructor(private cmsCachedService: CmsCachedService) {
    this.jobOpportunityStages$ = this.cmsCachedService.getUserDataByName("jo-stage");
  }

  ngOnInit() {
    setTimeout(() => {
      this.checkCanExpand();
    });
  }

  private checkCanExpand() {
    if (this.jobOfferDescription) {
      const contentHeight = this.jobOfferDescription.nativeElement.scrollHeight;
      this.canExpand = contentHeight > 63;
    }
  }

  toggleShowMore(event: Event) {
    event.stopImmediatePropagation();
    this.isExpanded = !this.isExpanded;
  }

  setJobOpportunityStage(stage: number) {
    this.stageUpdated.emit({ jobOffer: this.jobOffer, stage });
  }

  preventClick(event: Event) {
    event.stopPropagation();
  }

  protected readonly JobOpportunitySearchResultStatus = JobOpportunitySearchResultStatus;
}
