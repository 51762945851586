<ng-container *nzModalTitle>
  {{ "admin.manual-match.modal.title" | translate }}
</ng-container>

<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-label [nzSpan]="24">{{ "admin.manual-match.modal.mission-partner" | translate }}</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="{{ 'admin.manual-match.modal.required' | translate }}">
      <nz-select formControlName="missionPartnerId" (ngModelChange)="onMissionPartnerChange($event)">
        <nz-option *ngFor="let mp of missionPartners" [nzValue]="mp.id" [nzLabel]="mp.name"> </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzSpan]="24">{{ "admin.manual-match.modal.job-offer" | translate }}</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="{{ 'admin.manual-match.modal.required' | translate }}">
      <nz-select formControlName="jobOfferId" [nzLoading]="isLoadingJobOffers">
        <nz-option *ngFor="let jo of jobOffers" [nzValue]="jo.id" [nzLabel]="jo.name"> </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>

<div *nzModalFooter>
  <button nz-button nzType="default" (click)="onCancel()">
    {{ "admin.manual-match.modal.cancel" | translate }}
  </button>
  <button nz-button nzType="primary" (click)="onOk()">
    {{ "admin.manual-match.modal.ok" | translate }}
  </button>
</div>
