import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { AccountService } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.service";
import {
  TalentAccount,
  MissionPartnerBasicInfo,
} from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.types";

@Component({
  selector: "mh-manual-match-dialog",
  templateUrl: "./manual-match-dialog.component.html",
  styleUrls: ["./manual-match-dialog.component.less"],
})
export class ManualMatchDialogComponent implements OnInit {
  talent: TalentAccount;
  form!: UntypedFormGroup;
  missionPartners: MissionPartnerBasicInfo[] = [];
  jobOffers: any[] = [];
  isLoadingJobOffers = false;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
  ) {
    this.talent = this.modalData["talent"];
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      missionPartnerId: [null, [Validators.required]],
      jobOfferId: [{ value: null, disabled: true }, [Validators.required]],
    });
    this.loadMissionPartners();
  }

  loadMissionPartners() {
    this.accountService.getMissionPartnersWithActiveJobOffers().subscribe((data) => {
      this.missionPartners = data;
    });
  }

  onMissionPartnerChange(mpId: string) {
    if (!mpId) {
      this.form.get("jobOfferId")?.disable();
      this.jobOffers = [];
      return;
    }

    this.isLoadingJobOffers = true;
    this.form.get("jobOfferId")?.enable();

    this.accountService
      .getJobOpportunitiesForMissionPartnerWithoutTalent(mpId, this.talent.accountId.internalId)
      .subscribe({
        next: (jobOffers) => {
          this.jobOffers = jobOffers;
          this.isLoadingJobOffers = false;
        },
        error: () => {
          this.isLoadingJobOffers = false;
        },
      });
  }

  onCancel(): void {
    this.modal.close();
  }

  onOk(): void {
    if (this.form.valid) {
      this.modal.close(this.form.value);
    } else {
      Object.values(this.form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
