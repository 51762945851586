import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NegotiationDetailsDialogComponent } from "./negotiation-details-dialog.component";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzButtonModule } from "ng-zorro-antd/button";
import { TranslateModule } from "@ngx-translate/core";
import { NzGridModule } from "ng-zorro-antd/grid";

@NgModule({
  declarations: [NegotiationDetailsDialogComponent],
  imports: [CommonModule, TranslateModule, NzModalModule, NzButtonModule, NzGridModule],
  exports: [NegotiationDetailsDialogComponent],
})
export class NegotiationDetailsDialogModule {}
