import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { HomeAdminComponent } from "./home-admin.component";
import { HomeAdminRoutingModule } from "./home-admin-routing.module";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { TranslateModule } from "@ngx-translate/core";
import { NzButtonModule } from "ng-zorro-antd/button";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { AdminTalentListModule } from "./subpages/admin-talent-list/admin-talent-list.module";
import { AdminMissionPartnerListModule } from "./subpages/admin-mission-partner-list/admin-mission-partner-list.module";
import { AdminUserAdminListModule } from "./subpages/admin-user-admin-list/admin-user-admin-list.module";
import { LocaleSelectModule } from "@momhunting/common-ui";
import { AdminJobOfferListModule } from "./subpages/admin-job-offer-list/admin-job-offer-list.module";
import { AdminJobOfferMatchesModule } from "./subpages/admin-job-offer-matches/admin-job-offer-matches.module";
import { AdminMissionPartnerViewModule } from "./subpages/admin-mission-partner-view/admin-mission-partner-view.module";
import { AdminInvoiceListModule } from "./subpages/admin-invoices-list/admin-invoice-list.module";
import { AdminCommunicationsModule } from "./subpages/admin-communications/admin-communications.module";
import { AdminNegotiationListModule } from "./subpages/admin-negotiation-list/admin-negotiation-list.module";
import { DrawerModule, IconModule, NavBarModule, WingBlankModule, WhiteSpaceModule } from "ng-zorro-antd-mobile";
import { AdminAssessmentListModule } from "./subpages/admin-assessment-list/admin-assessment-list.module";
import { AdminAssessmentViewModule } from "./subpages/admin-assessment-view/admin-assessment-view.module";
import { HiringDashboardModule } from "./subpages/hiring-dashboard/hiring-dashboard.module";
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    HomeAdminRoutingModule,
    NzBreadCrumbModule,
    NzLayoutModule,
    NzMenuModule,
    NzIconModule,
    NzTypographyModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzToolTipModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    AdminTalentListModule,
    AdminMissionPartnerListModule,
    AdminUserAdminListModule,
    AdminJobOfferListModule,
    AdminJobOfferMatchesModule,
    LocaleSelectModule,
    AdminMissionPartnerViewModule,
    AdminInvoiceListModule,
    AdminCommunicationsModule,
    AdminNegotiationListModule,
    AdminAssessmentListModule,
    AdminAssessmentViewModule,
    NavBarModule,
    DrawerModule,
    WingBlankModule,
    WhiteSpaceModule,
    IconModule,
    HiringDashboardModule,
  ],
  declarations: [HomeAdminComponent],
  exports: [HomeAdminComponent],
  providers: [DatePipe],
})
export class HomeAdminModule {}
