<div class="hiring-dashboard">
  <div class="hiring-dashboard__filters">
    <nz-select
      class="hiring-dashboard__filter"
      [(ngModel)]="selectedMissionPartnerId"
      (ngModelChange)="onMissionPartnerChange($event)"
      nzPlaceHolder="Select Mission Partner"
      nzAllowClear
    >
      <nz-option *ngFor="let partner of missionPartners" [nzValue]="partner.id" [nzLabel]="partner.name"> </nz-option>
    </nz-select>

    <nz-select
      class="hiring-dashboard__filter"
      [(ngModel)]="selectedJobOpportunityId"
      (ngModelChange)="onJobOpportunityChange($event)"
      nzPlaceHolder="Select Job Opportunity"
      [nzDisabled]="!selectedMissionPartnerId"
      nzAllowClear
    >
      <nz-option *ngFor="let job of jobOpportunities" [nzValue]="job.id" [nzLabel]="job.name"> </nz-option>
    </nz-select>

    <button nz-button nzType="primary" class="hiring-dashboard__filter-submit" (click)="fetchDashboardData()">
      {{ "admin.filter.submit" | translate }}
    </button>
  </div>

  <nz-spin [nzSpinning]="loading">
    <div class="hiring-dashboard__columns" *ngIf="dashboardData">
      <div class="hiring-dashboard__column" *ngFor="let column of dashboardData.columns">
        <div class="hiring-dashboard__column-header">
          <h3>{{ column.name }}</h3>
          <span class="hiring-dashboard__column-count">{{ column.talents.length }}</span>
        </div>
        <div class="hiring-dashboard__column-content">
          <div class="hiring-dashboard__item" *ngFor="let talent of column.talents">
            <div class="hiring-dashboard__item-talent">
              <a [routerLink]="['/admin/talent-view', talent.accountId]" target="_blank">
                {{ talent.talentName }}
              </a>
            </div>
            <div class="hiring-dashboard__item-job">
              <a [routerLink]="['/admin/job-offer-matches', talent.jobOpportunityId]" target="_blank">
                {{ talent.jobOpportunityName }}
              </a>
            </div>
            <div class="hiring-dashboard__item-footer">
              <div class="hiring-dashboard__item-date" *ngIf="talent.reminderDate">
                {{ talent.reminderDate | date : "dd.MM.yyyy" }}
              </div>
              <button
                nz-button
                nzType="link"
                class="hiring-dashboard__reminder-btn"
                (click)="openReminderDialog(talent)"
              >
                <span nz-icon [nzType]="talent.reminderDate ? 'bell' : 'plus'" nzTheme="outline"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>
</div>
