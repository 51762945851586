import { Component, Inject, OnInit } from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { Negotiation } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.types";
import { AccountService } from "../../../../platform-pages/src/lib/pages/home-admin/home-admin-data/account.service";
import { AgreementTerms } from "../../../../platform-pages/src/lib/api/messages/messages.types";
@Component({
  selector: "mh-negotiation-details-dialog",
  templateUrl: "./negotiation-details-dialog.component.html",
  styleUrls: ["./negotiation-details-dialog.component.less"],
})
export class NegotiationDetailsDialogComponent implements OnInit {
  negotiation: Negotiation;
  terms: AgreementTerms | null = null;

  constructor(
    @Inject(NZ_MODAL_DATA) public modalData: any,
    private modal: NzModalRef,
    private accountService: AccountService,
  ) {
    this.negotiation = this.modalData["negotiation"];
  }

  ngOnInit(): void {
    if (this.negotiation.conversationId) {
      this.accountService.getHiringTerms(this.negotiation.conversationId).subscribe((terms) => {
        this.terms = terms;
      });
    }
  }

  onClose(): void {
    this.modal.close();
  }
}
