<div class="agreement-terms-info" *ngIf="agreementTerms">
  <h3 class="agreement-terms-title">{{ "inbox.agreementTerms.title" | translate }}</h3>

  <div class="agreement-terms-section">
    <div *ngIf="agreementTerms.companyName">
      <h4 class="card-list__item-value">
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.companyName.label'"></mh-explanation-label>
      </h4>
      <p class="card-list__item-label m-b-8">{{ agreementTerms.companyName }}</p>
    </div>

    <div *ngIf="agreementTerms.position">
      <h4 class="card-list__item-value">
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.position.label'"></mh-explanation-label>
      </h4>
      <p class="card-list__item-label m-b-8">{{ agreementTerms.position }}</p>
    </div>

    <div *ngIf="agreementTerms.date">
      <h4 class="card-list__item-value">
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.date.label'"></mh-explanation-label>
      </h4>
      <p class="card-list__item-label m-b-8">{{ agreementTerms.date | date : "dd.MM.YYYY" }}</p>
    </div>

    <div *ngIf="agreementTerms.salary">
      <h4 class="card-list__item-value">
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.salary.label'"></mh-explanation-label>
      </h4>
      <p class="card-list__item-label m-b-8">{{ agreementTerms.salary }}</p>
    </div>

    <div *ngIf="agreementTerms.bonus">
      <h4 class="card-list__item-value">
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.bonus.label'"></mh-explanation-label>
      </h4>
      <p class="card-list__item-label m-b-8">{{ agreementTerms.bonus }}</p>
    </div>

    <div *ngIf="agreementTerms.workingHours">
      <h4 class="card-list__item-value">
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.workingHours.label'"></mh-explanation-label>
      </h4>
      <p class="card-list__item-label m-b-8">{{ agreementTerms.workingHours }}</p>
    </div>

    <div *ngIf="agreementTerms.placeOfWork">
      <h4 class="card-list__item-value">
        <mh-explanation-label [labelKey]="'inbox.agreementTerms.placeOfWork.label'"></mh-explanation-label>
      </h4>
      <p class="card-list__item-label m-b-8">{{ agreementTerms.placeOfWork }}</p>
    </div>
  </div>
</div>
