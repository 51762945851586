import { Component, OnInit } from "@angular/core";
import { NzModalModule, NzModalRef } from "ng-zorro-antd/modal";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzFormModule } from "ng-zorro-antd/form";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "mh-reminder-dialog",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzDatePickerModule,
    NzFormModule,
    TranslateModule,
    NzModalModule,
  ],
  template: `
    <ng-container *nzModalTitle>
      {{ (data?.reminderDate ? "admin.reminder.modal.update" : "admin.reminder.modal.set") | translate }}
    </ng-container>

    <form nz-form>
      <nz-form-item>
        <nz-form-label [nzSpan]="24">{{ "admin.reminder.modal.date" | translate }}</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-date-picker [(ngModel)]="selectedDate" [nzFormat]="'dd.MM.yyyy'" name="reminderDate" style="width: 100%">
          </nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </form>

    <div *nzModalFooter>
      <button nz-button nzType="default" (click)="cancel()">
        {{ "cancel.button" | translate }}
      </button>
      <button *ngIf="data?.reminderDate" nz-button nzDanger (click)="remove()">
        {{ "remove.button" | translate }}
      </button>
      <button nz-button nzType="primary" (click)="save()" [disabled]="!selectedDate">
        {{ "save.button" | translate }}
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ReminderDialogComponent implements OnInit {
  constructor(private modalRef: NzModalRef<any, any>) {}

  get data() {
    return this.modalRef.getConfig().nzData?.data;
  }

  selectedDate: Date | null = null;

  ngOnInit() {
    if (this.data?.reminderDate) {
      this.selectedDate = new Date(this.data.reminderDate);
    }
  }

  save() {
    this.modalRef.close(this.selectedDate);
  }

  remove() {
    this.modalRef.close("remove");
  }

  cancel() {
    this.modalRef.close();
  }
}
