<nz-comment
  [nzDatetime]="timeLabel"
  [ngClass]="{
    'own-message': item.ownMessage,
    'system-message': item.messageType === MessageType.SYSTEM_MESSAGE,
    'action-message': isActionMessage,
    'agreement-message': isAgreementReached,
  }"
>
  <nz-comment-content>
    <div
      class="message-balloon"
      [ngClass]="{
        video: isVideoAttachment,
        audio: isAudioAttachment,
        image: isImageAttachment
      }"
    >
      <ng-container *ngIf="!isImageAttachment && !isVideoAttachment && !isAudioAttachment && !isAgreementReached"
        >{{ item.content | conversationTranslate : recipientInfo | async }}
      </ng-container>
      <ng-container *ngIf="isDocumentAttachment">
        <a [href]="item.attachment.fileUrl">{{ item.attachment.name }}</a>
      </ng-container>
      <ng-container *ngIf="isImageAttachment">
        <img nz-image class="attachment-image" [alt]="item.attachment.name" [nzSrc]="item.attachment.fileUrl" />
      </ng-container>
      <ng-container *ngIf="isVideoAttachment">
        <div class="video-title" *ngIf="item.attachment.beingTranscoded">
          {{ "message.video-processing.label" | translate }}
        </div>
        <video class="attachment-video" controls preload="metadata">
          <source [src]="item.attachment.fileUrl + '#t=0.5'" />
        </video>
      </ng-container>
      <ng-container *ngIf="isAudioAttachment">
        <audio controls class="attachment-audio">
          <source [src]="item.attachment.fileUrl" />
        </audio>
      </ng-container>
      <ng-container *ngIf="isAgreementReached">
        <nz-alert
          class="alert-cnt-center agreement-alert"
          nzType="success"
          [nzMessage]="item.content | conversationTranslate : recipientInfo | async"
          nzShowIcon
        ></nz-alert>
      </ng-container>

      <div *ngIf="showProfileAccessActionButtons" class="message-balloon-actions">
        <ng-container [ngTemplateOutlet]="actionsAccessRequest"></ng-container>
      </div>
      <div *ngIf="showAgreementReachedAction" class="message-balloon-actions">
        <ng-container [ngTemplateOutlet]="actionsAgreementReached"></ng-container>
      </div>
    </div>
  </nz-comment-content>
</nz-comment>

<ng-template #actionsAccessRequest>
  <nz-space nzDirection="horizontal">
    <button
      *nzSpaceItem
      nz-button
      nzSize="small"
      nzType="primary"
      [disabled]="isMessageActionsDisabled"
      (click)="doMessageAction('grant_profile_access')"
    >
      {{ "ok.button" | translate }}
    </button>
    <button
      *nzSpaceItem
      nz-button
      nzSize="small"
      nzType="default"
      nzDanger
      [disabled]="isMessageActionsDisabled"
      (click)="doMessageAction('reject_profile_access')"
    >
      {{ "later.button" | translate }}
    </button>
  </nz-space>
</ng-template>
<ng-template #actionsAgreementReached>
  <nz-space nzDirection="horizontal">
    <button
      *nzSpaceItem
      nz-button
      nzSize="small"
      nzType="primary"
      [disabled]="isMessageActionsDisabled"
      (click)="doMessageAction('grant_agreement_reached')"
    >
      {{ "inbox.confirm-agreement-reached.button" | translate }}
    </button>
  </nz-space>
</ng-template>
