import { Component, OnInit } from "@angular/core";
import { AccountService } from "../../home-admin-data/account.service";
import {
  HiringDashboardData,
  MissionPartnerBasicInfo,
  JobOpportunityBasicInfo,
} from "../../home-admin-data/account.types";
import { take } from "rxjs";
import { NzModalService } from "ng-zorro-antd/modal";
import { ReminderDialogComponent } from "./reminder-dialog/reminder-dialog.component";

@Component({
  selector: "mh-hiring-dashboard",
  templateUrl: "./hiring-dashboard.component.html",
  styleUrls: ["./hiring-dashboard.component.less"],
})
export class HiringDashboardComponent implements OnInit {
  dashboardData?: HiringDashboardData;
  loading = true;
  missionPartners: MissionPartnerBasicInfo[] = [];
  jobOpportunities: JobOpportunityBasicInfo[] = [];
  selectedMissionPartnerId = "";
  selectedJobOpportunityId = "";

  constructor(private accountService: AccountService, private modalService: NzModalService) {}

  ngOnInit() {
    this.loadMissionPartners();
    this.fetchDashboardData();
  }

  loadMissionPartners() {
    this.accountService
      .getMissionPartnersWithActiveJobOffers()
      .pipe(take(1))
      .subscribe((partners) => {
        this.missionPartners = partners;
      });
  }

  onMissionPartnerChange(missionPartnerId: string) {
    this.selectedMissionPartnerId = missionPartnerId;
    this.selectedJobOpportunityId = "";
    this.jobOpportunities = [];

    if (missionPartnerId) {
      this.accountService
        .getJobOpportunitiesForMissionPartner(missionPartnerId)
        .pipe(take(1))
        .subscribe((opportunities) => {
          this.jobOpportunities = opportunities;
        });
    }
  }

  onJobOpportunityChange(jobOpportunityId: string) {
    this.selectedJobOpportunityId = jobOpportunityId;
  }

  fetchDashboardData() {
    this.loading = true;

    const request = this.selectedMissionPartnerId
      ? this.accountService.getHiringDashboardWithFilters(this.selectedMissionPartnerId, this.selectedJobOpportunityId)
      : this.accountService.getHiringDashboard();

    request.pipe(take(1)).subscribe((data) => {
      this.dashboardData = data;
      this.loading = false;
    });
  }

  openReminderDialog(talent: any) {
    this.modalService
      .create({
        nzContent: ReminderDialogComponent,
        nzData: {
          data: talent,
        },
        nzFooter: null,
      })
      .afterClose.subscribe((result) => {
        if (result === "remove") {
          this.deleteReminder(talent);
        } else if (result instanceof Date) {
          this.setReminder(talent, result);
        }
      });
  }

  setReminder(talent: any, date: Date) {
    this.accountService
      .setHiringReminder(talent.accountId, talent.jobOpportunityId, date.getTime())
      .pipe(take(1))
      .subscribe(() => {
        this.fetchDashboardData();
      });
  }

  deleteReminder(talent: any) {
    this.accountService
      .deleteHiringReminder(talent.accountId, talent.jobOpportunityId)
      .pipe(take(1))
      .subscribe(() => {
        this.fetchDashboardData();
      });
  }
}
