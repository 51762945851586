<ng-container *ngIf="jobOfferEntity$ | async as jobOfferEntity; else loadingTmpl">
  <nz-page-header class="large p-l-8 p-r-0">
    <nz-page-header-extra>
      <form
        class="job-offer-form"
        nz-form
        [formGroup]="searchForm"
        [nzLayout]="'inline'"
        (ngSubmit)="submitFilterForm()"
      >
        <nz-form-item class="job-offer-form__filter">
          <nz-form-label *ngIf="!(isMobileView$ | async)">
            {{ "filter.placeholder" | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-select
              class="job-offer-form__matches-filter"
              nzSize="default"
              [nzDropdownMatchSelectWidth]="false"
              formControlName="matchesFilter"
              nzMode="default"
              [nzCustomTemplate]="(isMobileView$ | async) ? matchesFilterMobile : null"
              [nzShowArrow]="!(isMobileView$ | async)"
              [nzBorderless]="isMobileView$ | async"
              nzPlacement="bottomRight"
            >
              <nz-option [nzLabel]="'jo-match.filter.all' | translate" [nzValue]="''"></nz-option>
              <nz-option [nzLabel]="'jo-match.filter.favourite' | translate" [nzValue]="'FAVOURITE'"></nz-option>
              <nz-option [nzLabel]="'jo-match.filter.archived' | translate" [nzValue]="'ARCHIVED'"></nz-option>
            </nz-select>
            <ng-template #matchesFilterMobile>
              <button nz-button nzType="link" nzShape="circle">
                <span nz-icon nzType="filter"></span>
              </button>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="margin-right: 28px" class="job-offer-form__filter">
          <nz-form-label *ngIf="!(isMobileView$ | async)">
            {{ "jo-match.sort-by.label" | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-select
              class="job-offer-form__sort-by"
              formControlName="externalSort"
              [nzDropdownMatchSelectWidth]="false"
              [nzOptionOverflowSize]="10"
              [compareWith]="compareExternalSort"
              [nzCustomTemplate]="(isMobileView$ | async) ? sortByMobile : null"
              [nzShowArrow]="!(isMobileView$ | async)"
              [nzBorderless]="isMobileView$ | async"
              nzPlacement="bottomRight"
            >
              <nz-option
                [nzValue]="{ key: 'REG_TIMESTAMP', value: 'ascend' }"
                [nzLabel]="'jo-match.regdate.sort-asc' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="{ key: 'REG_TIMESTAMP', value: 'descend' }"
                [nzLabel]="'jo-match.regdate.sort-desc' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="{ key: 'NAME', value: 'ascend' }"
                [nzLabel]="'jo-match.name.sort-asc' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="{ key: 'NAME', value: 'descend' }"
                [nzLabel]="'jo-match.name.sort-desc' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="{ key: 'SALARY', value: 'ascend' }"
                [nzLabel]="'jo-match.salary.sort-asc' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="{ key: 'SALARY', value: 'descend' }"
                [nzLabel]="'jo-match.salary.sort-desc' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="{ key: 'DISTANCE', value: 'ascend' }"
                [nzLabel]="'jo-match.distance.sort-asc' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="{ key: 'DISTANCE', value: 'descend' }"
                [nzLabel]="'jo-match.distance.sort-desc' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="{ key: 'SCORE', value: 'ascend' }"
                [nzLabel]="'jo-match.score.sort-asc' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="{ key: 'SCORE', value: 'descend' }"
                [nzLabel]="'jo-match.score.sort-desc' | translate"
              ></nz-option>
            </nz-select>
            <ng-template #sortByMobile let-selected>
              <button nz-button nzType="link" nzShape="circle">
                <span
                  nz-icon
                  [nzType]="selected.nzValue.value === 'ascend' ? 'sort-ascending' : 'sort-descending'"
                  nzTheme="outline"
                ></span>
              </button>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <!--div nz-col>
          <nz-form-item>
            <nz-form-control>
              <button nz-button nzType="primary" [disabled]="searchForm.invalid">
                {{ "admin.filter.submit" | translate }}
              </button>
            </nz-form-control>
          </nz-form-item>
        </div-->
        <button
          *ngIf="!(isMobileView$ | async)"
          [disabled]="!$any(hasReviewedStatus$ | async)"
          class="job-offer-form__edit-btn"
          nz-button
          type="button"
          nzType="primary"
          [routerLink]="['/', 'mission-partner', 'job-offers', 'edit', jobOfferId]"
          [queryParams]="{ origin: 'job-offers-matches', originId: jobOfferId }"
        >
          {{ "job-offer.edit.button" | translate }}
        </button>
        <button
          *ngIf="isMobileView$ | async"
          [disabled]="!$any(hasReviewedStatus$ | async)"
          class="job-offer-form__edit-btn"
          nz-button
          nzType="link"
          nzShape="circle"
          [routerLink]="['/', 'mission-partner', 'job-offers', 'edit', jobOfferId]"
          [queryParams]="{ origin: 'job-offers-matches', originId: jobOfferId }"
        >
          <span nz-icon nzType="edit"></span>
        </button>
        <button
          class="job-offer-form__back-btn"
          nz-button
          type="button"
          nzType="primary"
          (click)="navigateToJOListPage()"
        >
          < {{ "job-offer.back-to-list.button" | translate }}
        </button>
      </form>
    </nz-page-header-extra>
  </nz-page-header>

  <mh-job-offer-matches-offer-view
    [jobOffer]="jobOfferEntity"
    (stageUpdated)="onStageUpdated($event)"
  ></mh-job-offer-matches-offer-view>
  <ng-container *ngIf="jobOfferMatchesList$ | async as jobOfferMatches; else loadingTmpl">
    <mh-job-offer-matches-list
      [matches]="jobOfferMatches"
      [jobOffer]="jobOfferEntity"
      [viewerAccountType]="AccountType.MISSION_PARTNER"
      [loading]="$any(loadingMatchesList$ | async)"
      [isMobile]="isMobileView$ | async"
      [hasReviewedStatus]="$any(hasReviewedStatus$ | async)"
      [hasSelfServiceStatus]="$any(hasSelfServiceStatus$ | async)"
      (talentClick)="onTalentClick($event)"
      (pageSizeChange)="onPageSizeChange($event)"
      (pageIndexChange)="onPageIndexChange($event)"
      (startConversation)="onStartConversation($event)"
      (toConversation)="onToConversation($event)"
      (doArchive)="onDoArchive($event)"
      (doUnArchive)="onDoUnArchive($event)"
      (openCommentModal)="onOpenCommentModal($event)"
      (setTalentFavourite)="onSetTalentFavourite($event)"
    ></mh-job-offer-matches-list>
  </ng-container>
</ng-container>

<ng-template #loadingTmpl>
  <nz-spin nzSimple></nz-spin>
</ng-template>
