import { Component, EventEmitter, Input, Output } from "@angular/core";
import { JobOffer, StageUpdateEvent } from "../../../../../../api/job-offer/job-offer.types";
import { CmsNameValue } from "../../../../../../api/cms/cms.types";
import { Router } from "@angular/router";

@Component({
  selector: "mh-job-offer-list-active",
  templateUrl: "./job-offer-list-active.component.html",
  styleUrls: ["./job-offer-list-active.component.less"],
})
export class JobOfferListActiveComponent {
  @Input() jobOfferList: Array<JobOffer> = [];
  @Input() hasReviewedStatus = false;
  @Input() stage?: CmsNameValue;
  @Output() archiveJobOffer = new EventEmitter<JobOffer>();
  @Output() stageUpdated = new EventEmitter<StageUpdateEvent>();
  constructor(private router: Router) {}

  get filteredJobOffers(): Array<JobOffer> {
    if (this.stage) {
      return this.jobOfferList.filter((offer) => offer.stage === this.stage?.value);
    }
    return this.jobOfferList;
  }

  onArchive(entity: JobOffer) {
    this.archiveJobOffer.emit(entity);
  }

  onStageUpdated(event: StageUpdateEvent) {
    this.stageUpdated.emit(event);
  }

  navigateToMatches(offerId: string) {
    this.router.navigate(["/", "mission-partner", "job-offers", "matches", offerId]);
  }
}
