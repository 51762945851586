import { Component, EventEmitter, Input, Output } from "@angular/core";
import { JobOffer, StageUpdateEvent } from "../../../../../api/job-offer/job-offer.types";
import { CmsNameValue } from "../../../../../api/cms/cms.types";
import { Observable } from "rxjs";
import { CmsCachedService } from "../../../../../api/cms/cms-cached.service";

@Component({
  selector: "mh-mp-job-offer-list",
  templateUrl: "./mp-job-offer-list.component.html",
  styleUrls: ["./mp-job-offer-list.component.less"],
})
export class MpJobOfferListComponent {
  @Input() jobOfferListActive: Array<JobOffer> = [];
  @Input() jobOfferListArchived: Array<JobOffer> = [];
  @Input() hasReviewedStatus = false;
  @Output() archiveJobOffer = new EventEmitter<JobOffer>();
  @Output() unArchiveJobOffer = new EventEmitter<JobOffer>();
  @Output() stageUpdated = new EventEmitter<StageUpdateEvent>();

  jobOpportunityStages$: Observable<Array<CmsNameValue>>;

  constructor(private cmsCachedService: CmsCachedService) {
    this.jobOpportunityStages$ = this.cmsCachedService.getUserDataByName("jo-stage");
  }

  onArchive(entity: JobOffer) {
    this.archiveJobOffer.emit(entity);
  }

  onUnArchive(entity: JobOffer) {
    this.unArchiveJobOffer.emit(entity);
  }

  onStageUpdated(event: StageUpdateEvent) {
    this.stageUpdated.emit(event);
  }
}
