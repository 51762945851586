import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MessagesConversationChatComponent } from "./messages-conversation-chat.component";
import { NzListModule } from "ng-zorro-antd/list";
import { NzCommentModule } from "ng-zorro-antd/comment";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzFormModule } from "ng-zorro-antd/form";
import { FormsModule } from "@angular/forms";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { EmojiPickerModule } from "./emoji-picker/emoji-picker.module";
import { MhChatMessageComponent } from "./mh-chat-message/mh-chat-message.component";
import { MhMessageInputComponent } from "./mh-message-input/mh-message-input.component";
import { MhChatParticipantInfoComponent } from "./mh-chat-participant-info/mh-chat-participant-info.component";
import { MhAgreementTermsInfoComponent } from "./mh-agreement-terms-info/mh-agreement-terms-info.component";
import { TagsOutputModule } from "../../../../../../../common-ui/src/lib/tags-output/tags-output.module";
import { NzTableModule } from "ng-zorro-antd/table";
import { LocationLabelModule } from "../../../../../../../common-ui/src/lib/location-label/location-label.module";
import { TranslateModule } from "@ngx-translate/core";
import { ExplanationLabelModule } from "../../../../../../../common-ui/src/lib/explanation-label/explanation-label.module";
import { MhMessageRecordVideoDialogComponent } from "./mh-message-record-video-dialog/mh-message-record-video-dialog.component";
import { VideoRecordingService } from "../../../../../../../core/src/lib/recording/video-recording.service";
import { MhMessageRecordAudioDialogComponent } from "./mh-message-record-audio-dialog/mh-message-record-audio-dialog.component";
import { AudioRecordingService } from "../../../../../../../core/src/lib/recording/audio-recording.service";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzImageModule } from "ng-zorro-antd/image";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { ConversationTranslatePipe } from "../../conversation-translate.pipe";

@NgModule({
  declarations: [
    MessagesConversationChatComponent,
    MhChatMessageComponent,
    MhMessageInputComponent,
    MhChatParticipantInfoComponent,
    MhAgreementTermsInfoComponent,
    MhMessageRecordVideoDialogComponent,
    MhMessageRecordAudioDialogComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NzListModule,
    NzCommentModule,
    NzAvatarModule,
    NzFormModule,
    FormsModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    EmojiPickerModule,
    TagsOutputModule,
    NzTableModule,
    LocationLabelModule,
    TranslateModule,
    ExplanationLabelModule,
    NzSpinModule,
    NzImageModule,
    NzAlertModule,
    NzSpaceModule,
    ConversationTranslatePipe,
  ],
  exports: [MessagesConversationChatComponent, MhChatParticipantInfoComponent, MhAgreementTermsInfoComponent],
  providers: [VideoRecordingService, AudioRecordingService],
})
export class MessagesConversationChatModule {}
