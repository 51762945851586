import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import {
  JobOffer,
  MatchedTalent,
  MatchedTalentStatus,
  StageUpdateEvent,
} from "../../../../api/job-offer/job-offer.types";
import { AccountService } from "../../home-admin-data/account.service";
import { ActivatedRoute, Router } from "@angular/router";
import { map, Observable, Subject, take, takeUntil, tap } from "rxjs";
import { AccountFilter, Filter } from "../../home-admin-data/account.types";
import { PaginatedContent } from "../../../../api/common.types";
import { NzModalService } from "ng-zorro-antd/modal";
import { TranslateService } from "@ngx-translate/core";
import { AdminJobOfferService } from "../../../../api/admin-job-offer/admin-job-offer.service";
import { AdminJobOfferTalentCommentsDialogComponent } from "./admin-job-offer-talent-comments-dialog/admin-job-offer-talent-comments-dialog.component";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { AccountType } from "../../../login-page/login-page-data/login.types";
import { FilterStorageService } from "../../../../api/filter-storage/filter-storage.service";
import { FiltersPage } from "../../../../api/filter-storage/filter-storage.types";
import { BreakpointObserver } from "@angular/cdk/layout";
import { AdminJobOfferEditComponent } from "../admin-job-offer-list/admin-job-offer-edit/admin-job-offer-edit.component";
import { PageName, PageParam } from "libs/platform-pages/src/lib/common-componnets/common-types";
import { CmsNameValue } from "libs/platform-pages/src/lib/api/cms/cms.types";
import { CmsService } from "libs/platform-pages/src/lib/api/cms/cms.service";
import { CmsCachedService } from "libs/platform-pages/src/lib/api/cms/cms-cached.service";

const FILTERS_PAGE = FiltersPage.ADMIN_JOB_OFFER_MATCHES;

@Component({
  selector: "mh-admin-job-offer-matches",
  templateUrl: "./admin-job-offer-matches.component.html",
  styleUrls: ["./admin-job-offer-matches.component.less"],
})
export class AdminJobOfferMatchesComponent implements OnInit, OnDestroy {
  jobOfferId?: string | null;
  jobOffer?: JobOffer;
  jobOfferMatches?: PaginatedContent<MatchedTalent>;
  loading = true;
  searchForm!: UntypedFormGroup;
  currentFilter?: AccountFilter;
  isMobileView$: Observable<boolean>;
  talentStages$!: Observable<CmsNameValue[]>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private filterStorageService: FilterStorageService,
    private route: ActivatedRoute,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private translateService: TranslateService,
    private adminJobOfferService: AdminJobOfferService,
    private fb: FormBuilder,
    private router: Router,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private cmsCachedService: CmsCachedService,
  ) {
    this.talentStages$ = this.cmsCachedService.getUserDataByName("jo-cm-stage");
    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 767px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      matchesFilter: [""],
      externalSort: [{ key: "SCORE", value: "descend" }],
    });

    this.searchForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.submitFilterForm();
    });

    this.jobOfferId = this.route.snapshot.paramMap.get("id");
    if (this.jobOfferId) {
      this.accountService
        .loadJobOffer(this.jobOfferId)
        .pipe(take(1))
        .subscribe((jobOffer) => {
          if (jobOffer) {
            this.jobOffer = jobOffer;
          }
        });

      this.initSavedFilters();
    }
  }

  private initSavedFilters(): void {
    const storedFilters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE] || {};
    if (storedFilters) {
      this.searchForm.patchValue(storedFilters);
    }
  }

  matchesReindex() {
    this.loading = true;
    if (this.jobOfferId) {
      this.accountService
        .loadJobOfferMatchesReindex(this.jobOfferId)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.fetchJobOfferMatches(this.getDefaultQueryParams());
          }
        });
    }
  }

  fetchJobOfferMatches(filter: AccountFilter) {
    this.loading = true;
    this.accountService
      .loadJobOfferMatches(filter)
      .pipe(take(1))
      .subscribe((paginatedContent) => {
        this.jobOfferMatches = paginatedContent;
        this.loading = false;
      });
  }

  getDefaultQueryParams() {
    const filter = {
      paging: {
        page: 1,
        itemsOnPage: 10,
      },
      filters: [
        {
          field: "JOB_OPPORTUNITY",
          value: this.jobOfferId || "",
        },
      ],
    };
    this.currentFilter = filter;
    return filter;
  }

  onArchiveMatchedTalent(entity: MatchedTalent, event?: Event) {
    if (event) event.stopPropagation();
    this.modal.confirm({
      nzTitle: this.translateService.instant("confirm.archive.label"),
      nzOkText: this.translateService.instant("ok.button"),
      nzCancelText: this.translateService.instant("cancel.button"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => {
        this.adminJobOfferService
          .archiveJobOfferMatch(this.jobOfferId as string, entity.accountId)
          .subscribe((success) => {
            if (success && this.jobOfferMatches?.items) {
              this.jobOfferMatches.items = this.jobOfferMatches.items.map((talent) => {
                if (talent.accountId === entity.accountId) {
                  return {
                    ...talent,
                    status: [...talent.status, MatchedTalentStatus.ARCHIVED],
                  };
                }
                return talent;
              });
            }
          });
      },
    });
  }

  onUnArchiveMatchedTalent(entity: MatchedTalent, event?: Event) {
    if (event) event.stopPropagation();
    this.adminJobOfferService
      .unArchiveJobOfferMatch(this.jobOfferId as string, entity.accountId)
      .subscribe((success) => {
        if (success && this.jobOfferMatches?.items) {
          this.jobOfferMatches.items = this.jobOfferMatches.items.map((talent) => {
            if (talent.accountId === entity.accountId) {
              return {
                ...talent,
                status: talent.status.filter((status) => status !== MatchedTalentStatus.ARCHIVED),
              };
            }
            return talent;
          });
        }
      });
  }

  submitFilterForm(): void {
    this.fetchJobOfferMatches(this.populateAccountFilter());
    this.loading = true;
  }

  getFilterFormValues() {
    const matchesFilterField = this.searchForm.get("matchesFilter");
    const filterFormFilters: Array<Filter> = [];
    if (matchesFilterField?.value) {
      filterFormFilters.push({
        field: matchesFilterField?.value,
        value: "true",
      });
    }
    return filterFormFilters;
  }

  private populateAccountFilter() {
    let filter = this.getDefaultQueryParams() as AccountFilter;
    const externalSort = this.searchForm.get("externalSort");
    if (externalSort?.value?.key) {
      filter = {
        ...filter,
        sort: [
          {
            order: externalSort.value.value == "ascend" ? "ASC" : "DESC",
            field: externalSort.value.key || "",
          },
        ],
      };
    }
    filter = {
      ...filter,
      filters: [...(filter.filters || []), ...this.getFilterFormValues()],
    };

    this.currentFilter = filter;
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    return filter;
  }

  onTalentClick(talent: MatchedTalent): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(["/admin/talent-view", talent.accountId]));
    window.open(url, "_blank");
  }

  onPageSizeChange(pageSize: number) {
    if (this.currentFilter) {
      const newFilter: AccountFilter = {
        ...this.currentFilter,
        paging: {
          ...this.currentFilter.paging,
          itemsOnPage: pageSize,
        },
      };
      this.currentFilter = newFilter;
      this.fetchJobOfferMatches(newFilter);
    }
  }

  onPageIndexChange(pageIndex: number) {
    if (this.currentFilter) {
      const newFilter: AccountFilter = {
        ...this.currentFilter,
        paging: {
          ...this.currentFilter.paging,
          page: pageIndex,
        },
      };
      this.currentFilter = newFilter;
      this.fetchJobOfferMatches(newFilter);
    }
  }

  onOpenCommentModal(talent: MatchedTalent) {
    if (!this.jobOfferId) {
      return;
    }

    const modal = this.modal.create({
      nzContent: AdminJobOfferTalentCommentsDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: false,
      nzCentered: true,
      nzWidth: "800px",
      nzStyle: { padding: "30px 0" },
      nzBodyStyle: { padding: "24px 24px 0" },
      nzData: {
        jobOfferId: this.jobOfferId,
        talent,
      },
      nzFooter: null,
    });
  }

  onSetTalentFavourite(talent: MatchedTalent) {
    if (!this.jobOfferId) {
      return;
    }

    this.adminJobOfferService
      .setTalentFavourite(this.jobOfferId, talent.accountId, !talent.favourite)
      .subscribe((success) => {
        if (success) {
          talent.favourite = !talent.favourite;
        }
      });
  }

  onSetTalentStage(event: { talent: MatchedTalent; stage: number }) {
    if (!this.jobOfferId) {
      return;
    }

    this.adminJobOfferService
      .setTalentStage(this.jobOfferId, event.talent.accountId, event.stage)
      .subscribe((success) => {
        if (success) {
          event.talent.stage = event.stage;
        }
      });
  }

  onJobOfferStageUpdated(event: StageUpdateEvent) {
    this.adminJobOfferService.setJobOpportunityStage(this.jobOfferId as string, event.stage).subscribe((success) => {
      if (success) {
        this.jobOffer = { ...this.jobOffer, stage: event.stage } as JobOffer;
      }
    });
  }

  onStartConversationAsAdmin(talent: MatchedTalent) {
    if (!this.jobOffer) {
      return;
    }

    this.accountService.signInAs(this.jobOffer?.missionPartnerId, PageName.MP_VIEW_TALENT, {
      [PageParam.TALENT_ID]: talent.accountId,
      [PageParam.JOB_OFFER_ID]: this.jobOfferId || "",
      [PageParam.OPEN_CONVERSATION]: "true",
    });
  }

  onToConversationAsAdmin(talent: MatchedTalent) {
    if (!this.jobOffer) {
      return;
    }

    this.accountService.signInAs(this.jobOffer?.missionPartnerId, PageName.MP_INBOX_CONVERSATION, {
      [PageParam.CONVERSATION_ID]: talent.conversationId || "",
    });
  }

  editJobOffer(): void {
    if (!this.jobOfferId) {
      return;
    }
    this.adminJobOfferService.getJobOpportunity(this.jobOfferId).subscribe((jo) => this.openEditJobOfferModal(jo));
  }

  openEditJobOfferModal(jobOffer: JobOffer) {
    const modal = this.modal.create({
      nzContent: AdminJobOfferEditComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzWidth: 800,
      nzData: { jobOffer },
      nzMaskClosable: false,
    });

    modal.afterClose.subscribe((result: JobOffer) => {
      if (result) {
        this.jobOffer = result;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  compareExternalSort(option1: any, option2: any): boolean {
    return option1 && option2 ? option1.key === option2.key && option1.value === option2.value : option1 === option2;
  }

  protected readonly AccountType = AccountType;
}
