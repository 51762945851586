<div *ngIf="jobOffer" class="job-offer m-b-16">
  <div nz-row [nzGutter]="[16, 16]" class="job-offer__details">
    <div nz-col [nzSpan]="24">
      <div class="job-offer__header">
        <div class="job-offer__header-row">
          <div class="job-offer__header-col">
            <div class="job-offer__name">
              {{ jobOffer.name }}
            </div>
          </div>
          <div
            *ngIf="jobOffer.salaryExpectations?.min?.name || jobOffer.salaryExpectations?.max?.name"
            class="job-offer__header-col job-offer__header-col--right"
          >
            <div class="job-offer__salary nowrap">
              <span class="job-offer__salary-value">
                {{ jobOffer.salaryExpectations?.min?.name }} - {{ jobOffer.salaryExpectations?.max?.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="job-offer__info-row">
          <div *ngIf="jobOffer.workExperience !== null" class="job-offer__info-value nowrap">
            <div class="job-offer__info-value-body">
              {{ "job-offer-matches.list.work-experience.title" | translate }}
              {{ jobOffer.workExperience }}
              {{ (jobOffer.workExperience === 1 ? "year.label" : "years.label") | translate }}
            </div>
          </div>
          <div *ngIf="jobOffer.leadershipExperience !== null" class="job-offer__info-value nowrap">
            <div class="job-offer__info-value-body">
              {{ "job-offer-matches.list.leadership-experience.title" | translate }}
              {{ jobOffer.leadershipExperience }}
              {{ (jobOffer.leadershipExperience === 1 ? "year.label" : "years.label") | translate }}
            </div>
          </div>
          <div class="job-offer__info-value nowrap">
            <div class="job-offer__info-value-body">
              {{ "job-offer-matches.detail.job-sharing.label" | translate }}
              {{
                (jobOffer.jobSharing
                  ? "job-offer-matches.detail.job-sharing.true"
                  : "job-offer-matches.detail.job-sharing.false"
                ) | translate
              }}
            </div>
          </div>
          <div *ngIf="jobOffer.leadershipTeamSize !== null" class="job-offer__info-value nowrap">
            <div class="job-offer__info-value-body">
              {{ "job-offer-matches.detail.leadership-team-size.label" | translate }}
              {{ jobOffer.leadershipTeamSize }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="jobOffer.description" nz-col [nzSpan]="24">
      <div class="job-offer__field job-offer__description-wrap">
        <div
          #jobOfferDescription
          [@showMore]="isExpanded ? 'show' : 'hide'"
          class="job-offer__value job-offer__description"
          [class.job-offer__description--h-auto]="!canExpand"
        >
          {{ jobOffer.description }}
        </div>

        <div class="job-offer__more">
          <button
            *ngIf="canExpand"
            class="job-offer__more-btn"
            [class.job-offer__more-btn--active]="isExpanded"
            nz-button
            nzSize="large"
            nzShape="circle"
            nzType="link"
            (click)="toggleShowMore()"
          >
            <span nz-icon nzType="down" nzTheme="outline"></span>
          </button>
        </div>
      </div>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="6">
      <div class="job-offer__field">
        <div class="job-offer__title">{{ "job-offer-matches.detail.sector.label" | translate }}</div>
        <mh-tags-output [value]="jobOffer.sectorFocus"></mh-tags-output>
      </div>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="6">
      <div class="job-offer__field">
        <div class="job-offer__title">{{ "job-offer-matches.list.domain-expertise.title" | translate }}</div>
        <mh-tags-output [value]="jobOffer.domainExpertise"></mh-tags-output>
      </div>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="6">
      <div class="job-offer__field">
        <div class="job-offer__title">{{ "job-offer-matches.detail.domain-preference.label" | translate }}</div>
        <mh-tags-output [value]="jobOffer.domainPreference"></mh-tags-output>
      </div>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="6">
      <div class="job-offer__field">
        <div class="job-offer__title">{{ "job-offer-matches.detail.industries.label" | translate }}</div>
        <mh-tags-output [value]="jobOffer.industries"></mh-tags-output>
      </div>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="6">
      <div class="job-offer__field">
        <div class="job-offer__title">{{ "profile.career-level.label" | translate }}</div>
        <mh-tags-output [value]="jobOffer.careerLevel"></mh-tags-output>
      </div>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="6">
      <div class="job-offer__field">
        <div class="job-offer__title">{{ "job-offer-matches.list.location.title" | translate }}</div>
        <mh-view-job-offer-location [jobOffer]="jobOffer"></mh-view-job-offer-location>
      </div>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="6">
      <div class="job-offer__field">
        <div class="job-offer__title">{{ "job-offer-matches.detail.language.label" | translate }}</div>
        <mh-tags-output [value]="jobOffer.workLanguage"></mh-tags-output>
      </div>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="3">
      <div class="job-offer__field">
        <div class="job-offer__title">{{ "job-offer-matches.detail.mode.label" | translate }}</div>
        <div *ngIf="jobOffer.mode; else noValueSpecified" class="job-offer__value">
          <mh-tags-output [value]="[jobOffer.mode]"></mh-tags-output>
        </div>
      </div>
    </div>
    <div nz-col [nzSpan]="24" [nzMd]="3">
      <div class="job-offer__field">
        <div class="job-offer__title">{{ "job-offer-matches.detail.stage.label" | translate }}</div>
        <div class="job-offer__value" (click)="preventClick($event)">
          <nz-select
            nzShowSearch
            [nzPlaceHolder]="'admin.stage.placeholder' | translate"
            [(ngModel)]="jobOffer.stage"
            (ngModelChange)="setJobOpportunityStage($event)"
            style="width: 100%"
          >
            <nz-option
              *ngFor="let stage of jobOpportunityStages$ | async"
              [nzLabel]="stage.name | translate"
              [nzValue]="stage.value"
            ></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noValueSpecified>-</ng-template>
