<nz-tabset>
  <nz-tab nzTitle="{{ 'job-offer.active.tab' | translate }}">
    <ng-template nz-tab>
      <mh-job-offer-list-active
        [jobOfferList]="jobOfferListActive"
        (archiveJobOffer)="onArchive($event)"
        [hasReviewedStatus]="hasReviewedStatus"
        (stageUpdated)="onStageUpdated($event)"
      ></mh-job-offer-list-active>
    </ng-template>
  </nz-tab>

  <!-- stages tabs in *ngFor -->
  <nz-tab *ngFor="let stage of jobOpportunityStages$ | async" [nzTitle]="stage.name | translate">
    <ng-template nz-tab>
      <mh-job-offer-list-active
        [jobOfferList]="jobOfferListActive"
        (archiveJobOffer)="onArchive($event)"
        [hasReviewedStatus]="hasReviewedStatus"
        [stage]="stage"
        (stageUpdated)="onStageUpdated($event)"
      ></mh-job-offer-list-active>
    </ng-template>
  </nz-tab>

  <nz-tab nzTitle="{{ 'job-offer.archived.tab' | translate }}">
    <ng-template nz-tab>
      <mh-job-offer-list-archived
        [jobOfferList]="jobOfferListArchived"
        (unArchiveJobOffer)="onUnArchive($event)"
        (stageUpdated)="onStageUpdated($event)"
      ></mh-job-offer-list-archived>
    </ng-template>
  </nz-tab>
</nz-tabset>
