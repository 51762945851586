<ng-container *nzModalTitle>
  {{ "inbox.agreementTerms.title" | translate }}
</ng-container>

<div class="profile-preview__section-content" *ngIf="terms">
  <div nz-row class="profile-preview__section-content">
    <ng-container *ngIf="terms.companyName">
      <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
        {{ "inbox.agreementTerms.companyName.label" | translate }}
      </h4>
      <p nz-col [nzSpan]="24" [nzMd]="18">
        {{ terms.companyName }}
      </p>
    </ng-container>

    <ng-container *ngIf="terms.position">
      <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
        {{ "inbox.agreementTerms.position.label" | translate }}
      </h4>
      <p nz-col [nzSpan]="24" [nzMd]="18">
        {{ terms.position }}
      </p>
    </ng-container>

    <ng-container *ngIf="terms.date">
      <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
        {{ "inbox.agreementTerms.date.label" | translate }}
      </h4>
      <p nz-col [nzSpan]="24" [nzMd]="18">
        {{ terms.date | date : "dd.MM.YYYY" }}
      </p>
    </ng-container>

    <ng-container *ngIf="terms.salary">
      <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
        {{ "inbox.agreementTerms.salary.label" | translate }}
      </h4>
      <p nz-col [nzSpan]="24" [nzMd]="18">
        {{ terms.salary }}
      </p>
    </ng-container>

    <ng-container *ngIf="terms.bonus">
      <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
        {{ "inbox.agreementTerms.bonus.label" | translate }}
      </h4>
      <p nz-col [nzSpan]="24" [nzMd]="18">
        {{ terms.bonus }}
      </p>
    </ng-container>

    <ng-container *ngIf="terms.workingHours">
      <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
        {{ "inbox.agreementTerms.workingHours.label" | translate }}
      </h4>
      <p nz-col [nzSpan]="24" [nzMd]="18">
        {{ terms.workingHours }}
      </p>
    </ng-container>

    <ng-container *ngIf="terms.placeOfWork">
      <h4 nz-col [nzSpan]="24" [nzMd]="6" class="profile-preview__item-label">
        {{ "inbox.agreementTerms.placeOfWork.label" | translate }}
      </h4>
      <p nz-col [nzSpan]="24" [nzMd]="18">
        {{ terms.placeOfWork }}
      </p>
    </ng-container>
  </div>
</div>

<ng-container *nzModalFooter>
  <button nz-button nzType="default" (click)="onClose()">
    {{ "close.button" | translate }}
  </button>
</ng-container>
