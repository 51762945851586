import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { Observable, take } from "rxjs";
import { PaginatedContent } from "../../../../api/common.types";
import {
  AccountFilter,
  AccountId,
  getStatusShortName,
  MissionPartnerAccount,
  TalentAccount,
} from "../../home-admin-data/account.types";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AccountService } from "../../home-admin-data/account.service";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzModalService } from "ng-zorro-antd/modal";
import { AccountPasswordDialogComponent } from "../../../../../../../common-ui/src/lib/account-password-dialog/account-password-dialog.component";
import { AccountStatus, AccountType } from "../../../login-page/login-page-data/login.types";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";
import { AccountCommentsDialogComponent } from "../../../../../../../common-ui/src/lib/account-comments-dialog/account-comments-dialog.component";
import { SalaryExpectation } from "../../../../../../../common-ui/src/lib/salary-expectation-input/salary-expectation.type";
import { CmsNameValue, compareCmsNameValue } from "../../../../api/cms/cms.types";
import { CmsCachedService } from "../../../../api/cms/cms-cached.service";
import { ValidateLocation } from "../../../../../../../common-ui/src/lib/location-input/location-input.validator";
import { Clipboard } from "@angular/cdk/clipboard";
import { FilterStorageService } from "../../../../api/filter-storage/filter-storage.service";
import { FiltersPage } from "../../../../api/filter-storage/filter-storage.types";
import { ManualMatchDialogComponent } from "../../../../../../../common-ui/src/lib/manual-match-dialog/manual-match-dialog.component";

const FILTERS_PAGE = FiltersPage.TALENT_LIST;

@Component({
  selector: "mh-admin-talent-list",
  templateUrl: "./admin-talent-list.component.html",
  styleUrls: ["./admin-talent-list.component.less"],
})
export class AdminTalentListComponent implements OnInit {
  accountList?: PaginatedContent<TalentAccount>;
  expertFields$: Observable<Array<CmsNameValue>>;
  industry$: Observable<Array<CmsNameValue>>;
  careerLevel$: Observable<Array<CmsNameValue>>;
  degree$: Observable<Array<CmsNameValue>>;
  sector$: Observable<Array<CmsNameValue>>;
  loading = true;
  isApiLoading = false;
  searchForm!: UntypedFormGroup;
  hoveredRowIndex: number | null = null;
  preventDoubleFetch = true;

  get showAdditionalInfo(): boolean {
    return this.searchForm.get("additionalInfo")?.value;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private filterStorageService: FilterStorageService,
    private notificationService: NzNotificationService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private readonly translateService: TranslateService,
    private datePipe: DatePipe,
    private cmsCachedService: CmsCachedService,
    private clipboard: Clipboard,
  ) {
    this.expertFields$ = this.cmsCachedService.getUserDataByName("expert-field");
    this.industry$ = this.cmsCachedService.getUserDataByName("industry");
    this.careerLevel$ = this.cmsCachedService.getUserDataByName("career-level", false);
    this.degree$ = this.cmsCachedService.getUserDataByName("degree");
    this.sector$ = this.cmsCachedService.getUserDataByName("sector");
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchTerm: [null],
      salary: { min: null, max: null },
      expertFields: [null],
      industry: [null],
      careerLevel: [null],
      location: [null, [ValidateLocation]],
      timeRange: [[]],
      additionalInfo: true,
      profileFilled: [null],
      openStatus: [null],
      degree: [null],
      sector: [null],
    });

    const storedFilters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE];
    if (storedFilters) {
      this.searchForm.patchValue(storedFilters);
    }

    this.fetchAccounts({ ...this.getDefaultQueryParams(), ...this.getFilterFormValues() });
  }

  fetchAccounts(filter: Partial<AccountFilter>) {
    this.loading = true;
    this.accountService
      .loadTalentList(filter as AccountFilter)
      .pipe(take(1))
      .subscribe((paginatedContent) => {
        this.accountList = paginatedContent;
        this.getSearchField("timeRange")?.patchValue(paginatedContent.timeRange);
        this.loading = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;
    if (this.preventDoubleFetch) {
      this.preventDoubleFetch = false;
      return;
    }
    const { pageSize, pageIndex, sort, filter } = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: Partial<AccountFilter> = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
      ...this.getFilterFormValues(),
    };

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == "ascend" ? "ASC" : "DESC",
            field: sortField,
          },
        ],
      };
    }

    this.onQueryChange(change);
    this.loading = true;
  }

  submitFilterForm(): void {
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    this.onQueryChange({
      paging: {
        page: 1,
        itemsOnPage: this.accountList?.paging?.itemsOnPage || 0,
      },
      ...this.getFilterFormValues(),
    } as AccountFilter);
  }

  getFilterFormValues() {
    const searchField = this.getSearchField("searchTerm");
    const timeRangeField = this.getSearchField("timeRange");
    const salaryField = this.getSearchField("salary");
    const expertFields = this.getSearchField("expertFields");
    const industry = this.getSearchField("industry");
    const careerLevel = this.getSearchField("careerLevel");
    const location = this.getSearchField("location");
    const profileFilled = this.getSearchField("profileFilled");
    const openStatus = this.getSearchField("openStatus");
    const degree = this.getSearchField("degree");
    const sector = this.getSearchField("sector");

    const filterFormValues: Partial<AccountFilter> = {
      searchTerm: searchField?.value || "",
      timeRange: timeRangeField?.value || [],
      filters: [],
      groupFilters: [],
      location: location?.value,
    };

    if (salaryField?.value) {
      const salary = salaryField.value as SalaryExpectation;
      if (salary.min?.value) {
        filterFormValues.filters?.push({
          field: "SALARY_EXPECTATION_MIN",
          value: salary.min?.value,
        });
      }
      if (salary.max?.value) {
        filterFormValues.filters?.push({
          field: "SALARY_EXPECTATION_MAX",
          value: salary.max?.value,
        });
      }
    }

    if (expertFields?.value?.length) {
      filterFormValues.groupFilters?.push({
        value: expertFields.value,
        field: "EXPERT_FIELD",
      });
    }

    if (industry?.value?.length) {
      filterFormValues.groupFilters?.push({
        value: industry.value,
        field: "INDUSTRY",
      });
    }

    if (careerLevel?.value?.length) {
      filterFormValues.groupFilters?.push({
        value: careerLevel.value,
        field: "CAREER_LEVEL",
      });
    }

    if (profileFilled?.value != null) {
      filterFormValues.filters?.push({
        value: profileFilled?.value,
        field: "PROFILE_FILL",
      });
    }

    if (openStatus?.value != null) {
      filterFormValues.filters?.push({
        value: openStatus?.value,
        field: "PROFILE_STATUS",
      });
    }

    if (degree?.value != null) {
      filterFormValues.groupFilters?.push({
        value: degree?.value,
        field: "DEGREE",
      });
    }

    if (sector?.value != null) {
      filterFormValues.groupFilters?.push({
        value: sector?.value,
        field: "SECTOR",
      });
    }

    return filterFormValues;
  }

  getSearchField(name: string) {
    return this.searchForm.get(name);
  }

  onSignInAs(accountId: string) {
    this.accountService.signInAs(accountId);
  }

  sendPasswordResetEmail(accountId: AccountId, email: string) {
    this.accountService.sendPasswordResetEmail(accountId, email).subscribe((success) => {
      if (success) {
        this.notificationService.success("", this.translateService.instant("admin.password-reset.success"));
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  openSetPasswordModal(account: TalentAccount | MissionPartnerAccount) {
    const modal = this.modal.create({
      nzContent: AccountPasswordDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: false,
      nzData: { account },
    });
  }

  openCommentModal(account: TalentAccount | MissionPartnerAccount) {
    const modal = this.modal.create({
      nzContent: AccountCommentsDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: false,
      nzCentered: true,
      nzWidth: "800px",
      nzStyle: { padding: "30px 0" },
      nzBodyStyle: { padding: "24px 24px 0" },
      nzData: { account },
      nzFooter: null,
    });
  }

  deleteAccount(accountId: AccountId, accountType = AccountType.TALENT) {
    this.accountService.deleteAccount(accountId, accountType).subscribe((success) => {
      if (success) {
        if (this.accountList?.items) {
          this.accountList.items = this.accountList.items.filter(
            (account) => account.accountId.internalId !== accountId.internalId,
          );
        }
        this.notificationService.success("", this.translateService.instant("admin.account-delete.success"));
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  showDeleteConfirm(account: TalentAccount | MissionPartnerAccount) {
    this.modal.confirm({
      nzTitle: this.translateService.instant("admin.account-delete.confirm.title"),
      nzContent: `<b>${this.translateService.instant("admin.account-delete.confirm.text")}</b>`,
      nzOkText: this.translateService.instant("admin.account-delete.confirm.ok.btn"),
      nzCancelText: this.translateService.instant("admin.account-delete.confirm.cancel.btn"),
      nzOkType: "primary",
      nzOkDanger: true,
      nzOnOk: () => this.deleteAccount(account.accountId),
    });
  }

  downloadData(accountId: AccountId, accountType = AccountType.TALENT) {
    const currentDate = new Date();
    const formattedDate = this.datePipe.transform(currentDate, "yyyy-MM-dd");
    const fileName = `${formattedDate}-${accountId.internalId}_export.json`;

    this.accountService.downloadData(accountId, accountType, fileName).subscribe();
  }

  downloadTalentListCSV() {
    const currentDate = new Date();
    const formattedDate = this.datePipe.transform(currentDate, "yyyy-MM-dd");
    const fileName = `${formattedDate}_talent_list_export.csv`;

    this.isApiLoading = true;
    this.accountService.downloadTalentListCSV(fileName).subscribe(() => (this.isApiLoading = false));
  }

  exportTalentToBrevo(talent: TalentAccount) {
    this.accountService.exportTalentProfileToBrevo(talent.accountId.internalId).subscribe((result) => {
      if (result) this.notificationService.success("", this.translateService.instant("profile.exported.notification"));
    });
  }

  exportTalentToOtys(talent: TalentAccount) {
    this.accountService.exportTalentProfileToOtys(talent.accountId.internalId).subscribe((result) => {
      if (result) this.notificationService.success("", this.translateService.instant("profile.exported.notification"));
    });
  }

  copyId(id: string) {
    const isCopySuccessful = this.clipboard.copy(id);
    if (isCopySuccessful) {
      this.notificationService.success("", this.translateService.instant("admin.copy-id.success"));
    }
  }

  setCommunityMemberStatus(talent: TalentAccount) {
    this.accountService
      .setCommunityMemberStatus(talent.accountId.internalId, !talent.communityMember)
      .subscribe((success) => {
        if (success) {
          talent.communityMember = !talent.communityMember;
          this.notificationService.success(
            "",
            this.translateService.instant("admin.set-community-member-status.success"),
          );
        } else {
          this.notificationService.error("", this.translateService.instant("notification.error"));
        }
      });
  }

  setConfirmedStatus(talent: TalentAccount) {
    this.accountService.addAccountConfirmedStatus(talent.accountId.internalId).subscribe((success) => {
      if (success) {
        if (!talent.statuses.includes(AccountStatus.CONFIRMED)) talent.statuses.push(AccountStatus.CONFIRMED);
        if (!talent.statuses.includes(AccountStatus.REGISTERED)) talent.statuses.push(AccountStatus.REGISTERED);
        this.notificationService.success("", this.translateService.instant("admin.add-confirmed-status.success"));
      } else {
        this.notificationService.error("", this.translateService.instant("notification.error"));
      }
    });
  }

  getSalaryExpectationLabel(salary: SalaryExpectation): string {
    return salary.min?.name + " - " + salary.max?.name;
  }

  onQueryChange(filter: Partial<AccountFilter>) {
    this.fetchAccounts(filter);
  }

  getDefaultQueryParams() {
    const from = new Date(2023, 0, 1);
    const to = new Date(new Date().getFullYear(), 11, 31);

    return {
      paging: {
        page: 1,
        itemsOnPage: 100,
      },
      searchTerm: "",
      timeRange: [from, to],
    };
  }

  openManualMatchModal(talent: TalentAccount): void {
    const modal = this.modal.create({
      nzTitle: this.translateService.instant("admin.manual-match.modal.title"),
      nzContent: ManualMatchDialogComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: { talent },
      nzFooter: null,
    });

    modal.afterClose.subscribe((result) => {
      if (result) {
        this.accountService.addTalentToJobOffer(talent.accountId.internalId, result.jobOfferId).subscribe((success) => {
          if (success) {
            this.notificationService.success("", this.translateService.instant("admin.manual-match.success"));
          } else {
            this.notificationService.error("", this.translateService.instant("notification.error"));
          }
        });
      }
    });
  }

  protected readonly getStatusShortName = getStatusShortName;
  protected readonly compareCmsNameValue = compareCmsNameValue;
  protected readonly AccountStatus = AccountStatus;
}
