<nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" nzAccept="image/png,image/jpeg,image/webp">
  <div class="mh-avatar-wrapper">
    <nz-avatar
      class="mh-avatar"
      [class.mh-avatar--logo]="isLogo"
      [nzSize]="120"
      [nzSrc]="croppedImage || avatarUrl"
    ></nz-avatar>
    <div class="mh-avatar-overlay">
      <span *ngIf="!(croppedImage || avatarUrl)" nz-icon nzType="upload"></span>
      <span *ngIf="croppedImage || avatarUrl" nz-icon nzType="edit"></span>
    </div>
    <nz-spin nzSimple *ngIf="uploading" class="mh-avatar-upload-spin"></nz-spin>
  </div>
</nz-upload>

<nz-modal
  *ngIf="!isLogo"
  [(nzVisible)]="isVisible"
  [nzTitle]="'avatar.cropper.title' | translate"
  (nzOnCancel)="handleCancel()"
  [nzClassName]="'avatar-picker'"
>
  <div *nzModalContent>
    <nz-spin nzSimple *ngIf="loading"></nz-spin>
    <image-cropper
      [imageFile]="imageFile"
      [aspectRatio]="1"
      [roundCropper]="true"
      format="jpeg"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      [resizeToWidth]="240"
      [resizeToHeight]="240"
      [onlyScaleDown]="true"
    ></image-cropper>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()" [nzLoading]="loading">
      {{ "cancel.button" | translate }}
    </button>
    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="loading">
      {{ "upload.button" | translate }}
    </button>
  </div>
</nz-modal>
