import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { PaginatedContent } from "../../../../api/common.types";
import { AccountFilter, Filter, Negotiation, NegotiationStatus } from "../../home-admin-data/account.types";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AccountService } from "../../home-admin-data/account.service";
import { take } from "rxjs";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { AccountType } from "../../../login-page/login-page-data/login.types";
import { FilterStorageService } from "../../../../api/filter-storage/filter-storage.service";
import { FiltersPage } from "../../../../api/filter-storage/filter-storage.types";
import { NzModalService } from "ng-zorro-antd/modal";
import { NegotiationDetailsDialogComponent } from "../../../../../../../common-ui/src/lib/negotiation-details-dialog/negotiation-details-dialog.component";

const FILTERS_PAGE = FiltersPage.NEGOTIATIONS;

@Component({
  selector: "mh-admin-negotiation-list",
  templateUrl: "./admin-negotiation-list.component.html",
  styleUrls: ["./admin-negotiation-list.component.less"],
})
export class AdminNegotiationListComponent implements OnInit {
  negotiations?: PaginatedContent<Negotiation>;
  loading = true;
  searchForm!: UntypedFormGroup;
  preventDoubleFetch = true;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private filterStorageService: FilterStorageService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchTerm: [null],
      negotiationStatuses: [NegotiationStatus.COMPLETED],
    });

    const storedFilters = this.filterStorageService.getStoredFilters()?.[FILTERS_PAGE];
    if (storedFilters) {
      this.searchForm.patchValue(storedFilters);
    }

    this.fetchAccounts({ ...this.getDefaultQueryParams(), ...this.getFilterFormValues() });
  }

  fetchAccounts(filter: AccountFilter) {
    this.loading = true;
    this.accountService
      .loadNegotiationsList(filter)
      .pipe(take(1))
      .subscribe((paginatedContent) => {
        this.negotiations = paginatedContent;
        this.loading = false;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    if (this.loading) return;
    if (this.preventDoubleFetch) {
      this.preventDoubleFetch = false;
      return;
    }
    const { pageSize, pageIndex, sort, filter } = params;

    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    let change: AccountFilter = {
      paging: {
        page: pageIndex,
        itemsOnPage: pageSize,
      },
      ...this.getFilterFormValues(),
    };

    if (sortField && sortOrder) {
      change = {
        ...change,
        sort: [
          {
            order: sortOrder == "ascend" ? "ASC" : "DESC",
            field: sortField,
          },
        ],
      };
    }

    this.onQueryChange(change);
    this.loading = true;
  }

  submitFilterForm(): void {
    this.filterStorageService.saveFilters(this.searchForm, FILTERS_PAGE);
    this.onQueryChange({
      paging: {
        page: 1,
        itemsOnPage: this.negotiations?.paging?.itemsOnPage || 0,
      },
      ...this.getFilterFormValues(),
    });
  }

  getFilterFormValues() {
    const searchField = this.getSearchField("searchTerm");
    const negotiationStatuses = this.getSearchField("negotiationStatuses");

    const filterFormValues = {
      searchTerm: searchField?.value || "",
      filters: [] as Filter[],
    };

    if (negotiationStatuses?.value) {
      filterFormValues.filters.push({
        value: negotiationStatuses.value,
        field: "STATUS",
      });
    }

    return filterFormValues;
  }

  getSearchField(name: string) {
    return this.searchForm.get(name);
  }

  onQueryChange(filter: AccountFilter) {
    this.fetchAccounts(filter);
  }

  getDefaultQueryParams() {
    return {
      paging: {
        page: 1,
        itemsOnPage: 100,
      },
      searchTerm: "",
    };
  }

  openNegotiationDetailsModal(negotiation: Negotiation) {
    const modal = this.modal.create({
      nzContent: NegotiationDetailsDialogComponent,
      nzMaskClosable: false,
      nzViewContainerRef: this.viewContainerRef,
      nzData: { negotiation },
      nzWidth: "800px",
    });
  }

  protected readonly NegotiationStatus = NegotiationStatus;
  protected readonly AccountType = AccountType;
}
